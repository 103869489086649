
import controlTitle from './img/control_title.png'
import bannerTermometro from './img/banner_termometro.png'
import termometro from './img/termometro.png'
import reportes from './img/reportes.png'
import clima from './img/clima.png'
import docs from './img/docs.png'

import tankTitle from './img/tank_title.png'
import bannerTank from './img/banner_tank.png'
import remanentes from './img/remanentes.png'
import tanque from './img/tanque.png'
import pantalla from './img/pantalla.png'
import sensores from './img/sensores.png'
import autonomia from './img/autonomia.png'
import gabinete from './img/gabinete.png'
import rastreo from './img/rastreo.png'
import seguridad from './img/seguridad.png'
import vigilancia from './img/vigilancia.png'

import trackitTitle from './img/trackit_title.png'
import bannerTrackit from './img/banner_trackit.png'
import acelerometro from './img/acelerometro.png'
import frenos from './img/frenos.png'
import monitoreo from './img/monitoreo.png'
import satelite from './img/satelite.png'
import lector from './img/lector.png'

import satelitalTitle from './img/satelital_title.png'
import bannerSatelital from './img/banner_satelital.png'
import mensajeria from './img/mensajeria.png'
import reportesalt from './img/reportesalt.png'
import alerta from './img/alerta.png'

import solarTitle from './img/solar_title.png'
import bannerSolar from './img/banner_solar.png'
import recargable from './img/recargable.png'
import instalacion from './img/instalacion.png'

import dashcamTitle from './img/dashcam_title.png'
import bannerDashcam from './img/banner_dashcam.png'
import grabacion from './img/grabacion.png'
import vision from './img/vision.png'
import conectividad from './img/conectividad.png'

export const services = {
    control: {
        title: controlTitle,
        desc: 'Nuestro equipo de control de temperatura para cajas refrigeradas está diseñado para soportar hasta 8 sensores.  Además, tiene capacidad de almacenamiento para 2000 posiciones y puede enviar alertas de temperatura personalizadas según a tus necesidades, es posible agregar cámaras de monitoreo dentro de las cajas.',
        banner: bannerTermometro,
        bannerClass: 'service-img-70',
        items: [
            {
                img: docs,
                name: 'Informes gráficos'
            },
            {
                img: clima,
                name: 'Medición de temperatura'
            },
            {
                img: reportes,
                name: 'Reportes dinámicos'
            },
            {
                img: termometro,
                name: 'Personalización de rangos de temperatura'
            }
        ],
        // SEO properties
        seoTitle: 'Control de Temperatura',
        seoDescription: 'En WTracking nuestro sistema de control de temperatura monitorea hasta 8 sensores, almacena 2000 posiciones y envía alertas personalizadas.',
        seoKeywords: 'WTracking, Sistemas de gestión de flotas, Monitoreo de flotas, Equipos de monitoreo, Soluciones integrales para flotas, Desarrollo de productos, Tecnología para flotas, Optimización de flotas ,Eficiencia en flotas ,Seguridad en flotas, Gestión de flotas personalizada, flotas con control de temperatura, Cajas refrigeradas, Sensores de temperatura en flotas, Monitoreo de carga, Seguridad de la carga, Cadena de frío',
    },
    tank: {
        title: tankTitle,
        desc: 'Es un sistema integral de manufactura propia, patentado y único en el mercado. Ofrece un monitoreo seguro de los accesos al tanque en todo momento, proporcionando funcionalidades ideales para controlar todas las actividades relacionadas con su carga.',
        banner: bannerTank,
        bannerClass: 'service-img-100',
        items: [
            {
                img: remanentes,
                name: 'Sensor de remanentes'
            },
            {
                img: tanque,
                name: 'Sensor de inclinación del tanque'
            },
            {
                img: pantalla,
                name: 'Pantalla de sellado electrónico'
            },
            {
                img: sensores,
                name: 'Compatibilidad con otros sensores'
            },
            {
                img: autonomia,
                name: 'Autonomía (Panel solar)'
            },
            {
                img: gabinete,
                name: 'Gabinete de seguridad'
            },
            {
                img: rastreo,
                name: 'Rastreo en tiempo real'
            },
            {
                img: seguridad,
                name: 'Instalación de seguridad'
            },
            {
                img: vigilancia,
                name: 'Vigilancia en caja de vávulas y domo'
            },
        ],
        // SEO properties
        seoTitle: 'Track Tank',
        seoDescription: 'Nuestro sistema para tanques ofrece un monitoreo completo de los accesos, brindando control total sobre todas las actividades de carga.',
        seoKeywords: 'WTracking, Sistemas de gestión de flotas, Monitoreo de flotas, Equipos de monitoreo, Soluciones integrales para flotas, Desarrollo de productos, Tecnología para flotas, Optimización de flotas ,Eficiencia en flotas ,Seguridad en flotas, Gestión de flotas personalizada, Sistema de monitoreo de tanques, Monitoreo de accesos en tanques, Seguridad de tanques',
    },
    trackit: {
        title: trackitTitle,
        desc: 'Es un sistema integral de acompañamiento al conductor fabricado internamente, único en el mercado. Combina GPS, control de velocidad, cámaras de fatiga, ruteo inteligente en el equipo, fotografía, audio bidireccional, telemetría del ECM del motor vía CaNBUS y diversos sensores, proporcionando información relevante sobre la unidad tanto para la empresa como para el conductor.',
        banner: bannerTrackit,
        bannerClass: 'service-img-100',
        items: [
            {
                img: vigilancia,
                name: 'Captura y grabación al chofer y el camino'
            },
            {
                img: acelerometro,
                name: 'Acelerómetro'
            },
            {
                img: frenos,
                name: 'Frenos electromecánicos'
            },
            {
                img: monitoreo,
                name: 'Pantalla para monitoreo de camino'
            },
            {
                img: satelite,
                name: 'Rastreo Satelital'
            },
            {
                img: lector,
                name: 'Lector Dallas'
            },
        ],
        // SEO properties
        seoTitle: 'Track It',
        seoDescription: 'Revoluciona la gestión de tu flota. Nuestro sistema integral combina GPS, cámaras, telemetría y más, proporcionando información vital para mejorar la seguridad.',
        seoKeywords: 'WTracking, Sistemas de gestión de flotas, Monitoreo de flotas, Equipos de monitoreo, Soluciones integrales para flotas, Desarrollo de productos, Tecnología para flotas, Optimización de flotas ,Eficiencia en flotas ,Seguridad en flotas, Gestión de flotas personalizada, Sistema de acompañamiento al conductor, GPS para flotas, CaNBUS, Seguridad vial, Monitoreo de conductores',
    },
    satelital: {
        title: satelitalTitle,
        desc: 'Es un rastreador para localizar activos fijos y móviles a través de la transmisión de datos de forma satelital. Es una unidad pequeña y fácil de montar, alimentada por línea o batería. Ideal para enviar coordenadas GPS a intervalos largos.',
        banner: bannerSatelital,
        bannerClass: 'service-img-100',
        items: [
            {
                img: mensajeria,
                name: 'Mensajería estándar'
            },
            {
                img: acelerometro,
                name: 'Acelerómetro'
            },
            {
                img: reportesalt,
                name: 'Reportes alternativos'
            },
            {
                img: alerta,
                name: 'Alerta de desvío de ruta'
            }
        ],
        // SEO properties
        seoTitle: 'Satelital Track',
        seoDescription: 'Conoce la ubicación de tus activos en todo momento. Nuestro rastreador satelital, pequeño y fácil de instalar, brinda la información necesaria para gestionar tu flota.',
        seoKeywords: 'WTracking, Sistemas de gestión de flotas, Monitoreo de flotas, Equipos de monitoreo, Soluciones integrales para flotas, Desarrollo de productos, Tecnología para flotas, Optimización de flotas, Eficiencia en flotas, Seguridad en flotas, Gestión de flotas personalizada, Rastreador satelital, Localización de activos, GPS satelital',
    },
    solar: {
        title: solarTitle,
        desc: 'Es un rastreador para localizar activos fijos y móviles a través de la transmisión de datos de forma satelital. Es una unidad pequeña y fácil de montar, alimentada por línea o batería. Ideal para enviar coordenadas GPS a intervalos largos.',
        banner: bannerSolar,
        bannerClass: 'service-img-100',
        items: [
            {
                img: recargable,
                name: 'Recargable con energía solar'
            },
            {
                img: rastreo,
                name: 'Informe de localización'
            },
            {
                img: reportesalt,
                name: 'Reportes dinámicos'
            },
            {
                img: alerta,
                name: 'Alerta de desvío de ruta'
            },
            {
                img: acelerometro,
                name: 'Acelerómetro'
            },
            {
                img: instalacion,
                name: 'Fácil instalación'
            }
        ],
        // SEO properties
        seoTitle: 'Solar Track',
        seoDescription: 'SolarTrack es el rastreador satelital ideal para una localización precisa de tus activos ya que te ofrece la tranquilidad de saber dónde se encuentran tus bienes.',
        seoKeywords: 'WTracking, Sistemas de gestión de flotas, Monitoreo de flotas, Equipos de monitoreo, Soluciones integrales para flotas, Desarrollo de productos, Tecnología para flotas, Optimización de flotas, Eficiencia en flotas, Seguridad en flotas, Gestión de flotas personalizada, rastreador satelital, SolarTrack, localización de activos',
    },
    dashcam: {
        title: dashcamTitle,
        desc: 'Es un equipo instalado en el parabrisas que cuenta con dos cámaras diseñadas para monitorear la conducción segura y prevenir accidentes. Siendo una herramienta valiosa para el monitoreo y alertas en caso de distracción o fátiga.',
        banner: bannerDashcam,
        bannerClass: 'service-img-100',
        items: [
            {
                img: grabacion,
                name: 'Grabación continua'
            },
            {
                img: remanentes,
                name: 'Telemetría CANBUS'
            },
            {
                img: vision,
                name: 'Visión nocturna'
            },
            {
                img: rastreo,
                name: 'GPS y seguimiento'
            },
            {
                img: conectividad,
                name: 'Conectividad remota'
            },
            {
                img: vigilancia,
                name: 'Camára de fátiga y distracción'
            }
        ],
        // SEO properties
        seoTitle: 'Dash Cam',
        seoDescription: 'Protege a tus conductores y flota. Nuestro sistema de monitoreo con doble cámara detecta distracciones y fatiga, alertando en tiempo real para prevenir accidentes.',
        seoKeywords: 'WTracking, Sistemas de gestión de flotas, Monitoreo de flotas, Equipos de monitoreo, Soluciones integrales para flotas, Desarrollo de productos, Tecnología para flotas, Optimización de flotas, Eficiencia en flotas, Seguridad en flotas, Gestión de flotas personalizada',
    }
}