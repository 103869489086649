import React, {  useEffect } from 'react';
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import ReactPlayer from 'react-player'

import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from 'react-bootstrap/Carousel'

import truck from './img/truck.png'
import nosotros from './img/nosotros.png'
import soluciones from './img/soluciones.png'

import control from './img/carousel/control.png'
import satelital from './img/carousel/satelital.png'
import tank from './img/carousel/tank.png'
import trackit from './img/carousel/trackit.png'
import dashcam from './img/carousel/dashcam.png'
import solar from './img/carousel/solar.png'

import control_m from './img/carousel/control_m.png'
import satelital_m from './img/carousel/satelital_m.png'
import tank_m from './img/carousel/tank_m.png'
import trackit_m from './img/carousel/trackit_m.png'
import dashcam_m from './img/carousel/dashcam_m.png'
import solar_m from './img/carousel/solar_m.png'

function Home({ refUs, refServices }) {
  const count = useMotionValue(0);
  const positionCount = useMotionValue(0);
  const yearsCount = useMotionValue(0);
  const hoursCount = useMotionValue(0);
  const daysCount = useMotionValue(0);

  const rounded = useTransform(count, Math.round);
  const positions = useTransform(positionCount, Math.round);
  const years = useTransform(yearsCount, Math.round);
  const hours = useTransform(hoursCount, Math.round);
  const days = useTransform(daysCount, Math.round);

  useEffect(() => {
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      
      <div>
        <ReactPlayer className="player-v" url='wtracking.mp4' playing={ true } loop={ true } volume={ 0 } muted={ true } controls={ false }/>
      </div>

      <div className='section' ref={ refUs }>
        <Container>
          <Row>
            <Col md={ 7 }>
              <img src={ truck } className='img-100 truck-mobile' alt="."/>
            </Col>
            <Col md={ 5 } xs={ 12 }>
              <div><img src={ nosotros } className='img-100' alt="."/></div>
              <br/>
              <div className='home-desc'>Somos una empresa especializada en proporcionar sistemas de vanguardia para la gestión de flotas, así como en la fabricación y distribución de equipos para monitoreo y control. Nos distinguimos por ofrecer a nuestros clientes soluciones integrales adaptadas a sus requerimientos específicos. Además, nos enfocamos en el constante desarrollo de nuevos productos para mantenernos a la vanguardia de la tecnología.</div>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col lg={ 3 } md={ 4 } xs={ 12 } align="center">
              <div className='us-item-container'>
                <div className='us-item-title'>+<motion.span initial="hidden" whileInView={ () => animate(positionCount, 4, {duration: 2 }) } viewport={{ once: true }}>{ positions }</motion.span>,000,000</div>
                <div className='us-item-desc'>Posiciones recibidas diariamente</div>
              </div>
            </Col>
            <Col lg={ 3 } md={ 4 } xs={ 12 } align="center">
              <div className='us-item-container'>
                <div className='us-item-title'><motion.span initial="hidden" whileInView={ () => animate(yearsCount, 15, {duration: 2 }) } viewport={{ once: true }}>{ years }</motion.span></div>
                <div className='us-item-desc'>Años implementando soluciones de rastreo</div>
              </div>
            </Col>
            <Col lg={ 3 } md={ 4 } xs={ 12 } align="center">
              <div className='us-item-container'>
                <div className='us-item-title'><motion.span initial="hidden" whileInView={ () => animate(hoursCount, 24, {duration: 2 }) } viewport={{ once: true }}>{ hours }</motion.span>/<motion.span initial="hidden" whileInView={ () => animate(daysCount, 7, {duration: 2 }) } viewport={{ once: true }}>{ days }</motion.span></div>
                <div className='us-item-desc'>Ofrecemos soporte a tu flotilla</div>
              </div>
            </Col>
            <Col lg={ 3 } md={ 4 } xs={ 12 } align="center">
              <div className='us-item-container'>
                <div className='us-item-title'><motion.span initial="hidden" whileInView={ () => animate(count, 78, {duration: 2 }) } viewport={{ once: true }}>{ rounded }</motion.span>%</div>
                <div className='us-item-desc'>Reducción de robo de combustible</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='section' ref={ refServices }>
        <Container>
          <Row>
            <Col md={ 3 }></Col>
            <Col md={ 6 }>
              <div></div><img src={ soluciones } className='img-100' style={{ position: 'relative' }} alt="."/>
            </Col>
            <Col md={ 3 }></Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col className='no-padding'>
              <Carousel>
                <Carousel.Item style={{ cursor: 'pointer' }} onClick={ () => window.location.href = '/tank' }>
                  <div className='carousel-item-container'>
                    <img src={ tank } className='img-100 carousel-display-web' alt="."/>
                    <img src={ tank_m } className='img-100 carousel-display-mobile' alt="."/>
                    <div align="center">
                      <div className='carousel-title-3 carousel-width'>
                        <div className='carousel-text carousel-text-color'>TRACK TANK</div><br/>
                        <div className='carousel-botton'> Conoce más </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item style={{ cursor: 'pointer' }} onClick={ () => window.location.href = '/trackit' }>
                  <div className='carousel-item-container'>
                    <img src={ trackit } className='img-100 carousel-display-web' alt="."/>
                    <img src={ trackit_m } className='img-100 carousel-display-mobile' alt="."/>
                    <div align="center">
                      <div className='carousel-title-7 carousel-width'>
                        <div className='carousel-text carousel-text-color'>Track It</div><br/>
                        <div className='carousel-botton'> Conoce más </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item style={{ cursor: 'pointer' }} onClick={ () => window.location.href = '/dashcam' }>
                  <div className='carousel-item-container'>
                    <img src={ dashcam } className='img-100 carousel-display-web' alt="."/>
                    <img src={ dashcam_m } className='img-100 carousel-display-mobile' alt="."/>
                    <div align="center">
                      <div className='carousel-title-6 carousel-width'>
                        <div className='carousel-text carousel-text-color'>DASH CAM</div><br/>
                        <div className='carousel-botton'> Conoce más </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item style={{ cursor: 'pointer' }} onClick={ () => window.location.href = '/control' }>
                  <div className='carousel-item-container'>
                    <img src={ control } className='img-100 carousel-display-web' alt="."/>
                    <img src={ control_m } className='img-100 carousel-display-mobile' alt="."/>
                    <div align="center">
                      <div className='carousel-title-2 carousel-width'>
                        <div className='carousel-text carousel-text-color'>CONTROL DE TEMPERATURA</div><br/>
                        <div className='carousel-botton'> Conoce más </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item style={{ cursor: 'pointer' }} onClick={ () => window.location.href = '/satelital' }>
                  <div className='carousel-item-container'>
                    <img src={ satelital } className='img-100 carousel-display-web' alt="."/>
                    <img src={ satelital_m } className='img-100 carousel-display-mobile' alt="."/>
                    <div align="center">
                      <div className='carousel-title-4 carousel-width'>
                        <div className='carousel-text carousel-text-color'>SATELITAL TRACK</div><br/>
                        <div className='carousel-botton'> Conoce más </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item style={{ cursor: 'pointer' }} onClick={ () => window.location.href = '/solar' }>
                  <div className='carousel-item-container'>
                    <img src={ solar } className='img-100 carousel-display-web' alt="."/>
                    <img src={ solar_m } className='img-100 carousel-display-mobile' alt="."/>
                    <div align="center">
                      <div className='carousel-title-5 carousel-width'>
                        <div className='carousel-text carousel-text-color'>SOLAR TRACK</div><br/>
                        <div className='carousel-botton'> Conoce más </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
      
    </div>
  );
}

export default Home;