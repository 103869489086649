import React, {  useState, useEffect, useRef } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Home from './Home'
import Footer from './Footer'
import Services from './Services'

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import logo from './img/logo.png'

const navPath = [ 'nosotros', 'soluciones', 'contacto' ]

function App() {
  const [ path, ] = useState( window.location.pathname.replace(/\//g, '') )

  const nosotrosRef = useRef()
  const solucionesRef = useRef()
  const contactoRef = useRef()

  useEffect(() => {
    if( sessionStorage.getItem('section') ) {
      var section = sessionStorage.getItem('section')

      eval(section + 'Ref').current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })

      sessionStorage.removeItem( 'section' )
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChangeNav = ( section ) => {
    if( navPath.includes( section ) && path !== '' && path !== '/' && section !== 'contacto' ) {
      sessionStorage.setItem('section', section);
      window.location.href = '/'
    } else {
    
      eval(section + 'Ref').current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  return (
    <div>

      <Navbar expand="lg" className="bg-body-tertiary" fixed="top">
        <Container>
          <Navbar.Brand>
            <div onClick={ () => window.location.href = '/' } style={{ cursor: 'pointer' }}> <img src={ logo } className='logo' alt="."/> </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav>
                <Nav.Link href="/" className="nav-item-text">Inicio</Nav.Link>
                <Nav.Link onClick={ () => handleOnChangeNav( 'nosotros' ) } className="nav-item-text nav-item-text-separator">Nosotros</Nav.Link>
                <Nav.Link onClick={ () => handleOnChangeNav( 'soluciones' ) } className="nav-item-text nav-item-text-separator">Soluciones</Nav.Link>
                <Nav.Link onClick={ () => handleOnChangeNav( 'contacto' ) } className="nav-item-text nav-item-text-botton">Contacto</Nav.Link>
              </Nav>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{ height: '67px' }} />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home refUs={ nosotrosRef } refServices={ solucionesRef } />} />
          <Route path={ '/' + path } element={<Services path={ path } />} />
        </Routes>
      </BrowserRouter>

      <div ref={ contactoRef }>
        <Footer />
      </div>

      {/* Botón flotante de WhatsApp */}
      <a
        href="https://wa.me/525641495696?text=Hola,%20me%20gustaría%20recibir%20más%20información%20acerca%20de%20sus%20servicios." // Reemplaza con tu número de WhatsApp
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="WhatsApp" className="whatsapp_icon"/>
      </a>

    </div>
  );
}

export default App;