import React, {  useState, useEffect } from 'react';
import { services } from './serviceJson';
import { Helmet } from 'react-helmet'; // Importamos react-helmet

import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import trackit from "./img/services/trackit.png"
import tank from "./img/services/tank.png"
import solar from "./img/services/solar.png"
import satelital from "./img/services/satelital.png"
import dashcam from "./img/services/dashcam.png"
import control from "./img/services/control.png"

function Services({ path }) {
  const [ service, ] = useState(services[ path ])
  const [ , setStrength ] = useState( 70 )
  const [ image, setImage ] = useState(  )

  useEffect(() => {
    var w = window.innerWidth;

    if( w <= 460 ) setStrength( 0 )

    if( path === 'trackit' ) setImage( trackit )
    else if( path === 'tank' ) setImage( tank )
    else if( path === 'solar' ) setImage( solar )
    else if( path === 'satelital' ) setImage( satelital )
    else if( path === 'dashcam' ) setImage( dashcam )
    else if( path === 'control' ) setImage( control )

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* Helmet para SEO dinámico */}
      <Helmet>
        <title>{service.seoTitle} | WTracking</title>
        <meta name="description" content={service.seoDescription} />
        <meta name="keywords" content={service.seoKeywords} />
      </Helmet>

      <div className='parallax-conteiner'>
        <img src={ image } className='img-100' alt="." />
      </div>

      <div className='parallax-conteiner-mobile'>
        <img src={ image } className='img-100' alt="."/>
      </div>

      <div className='section-simple'>
        <Container>
          <Row>
            <Col md={ 8 }>
              <div align="center"> <img src={ service.title } className='service-title' alt="."/></div>
              <br/><br/>
              <div className='home-desc'>{ service.desc }</div>
              <br/><br/><br/>
              <Container>
                <Row>
                  { service.items.map( item => (
                    <Col lg={ 2 } md={ 3 } xs={ 6 } align="center">
                      <div><img src={ item.img } className='bullet-service' alt="."/></div>
                      <div className='bullet-text'>{ item.name }</div>
                    </Col>
                  )) }
                </Row>
              </Container>
            </Col>

            <Col md={ 4 }>
              <div align="center">
                <img src={ service.banner } className={ service.bannerClass + ' banner-mobile' } alt="."/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


    </div>
  );
}

export default Services;